// TODO:  https://cncenter.atlassian.net/browse/FEG-1287

import * as Sentry from '@sentry/browser';
import addScriptToDOM from '@utils/addScript';
import videoAdRequest from '../components/gjvp/videoAdRequest';
import videoEvents from '../components/gjvp/videoEvents';
import { GjvpPlayerConfigType, GjvpVideosType } from '../types/gjvpPlayer';

const gjvpVideos = (window.gjvpVideos || []) as GjvpVideosType[];
const typePlayerVideoOrAudio =
  gjvpVideos[0] && gjvpVideos[0].templateLocation === 'audio' ? 'audio-player' : 'player'; // 'video' | 'audio'
const API_URL = `https://host.vpplayer.tech/${typePlayerVideoOrAudio}`;

// FN pro nacteni reklamni url
// Dostupné i při 0 videích na stránce - využívají je i jiné služby (např. přehrávač Tivio)
const videoAdReq = videoAdRequest();
window.getPreRollUrl = videoAdReq.getPreRollUrl.bind(videoAdReq.getPreRollUrl);
window.getMidRollUrl = videoAdReq.getMidRollUrl.bind(videoAdReq.getMidRollUrl);
window.getPostRollUrl = videoAdReq.getPostRollUrl.bind(videoAdReq.getPostRollUrl);

if (gjvpVideos.length > 0) {
  const projectID = gjvpVideos[0].playerProjectId || 'v2.0.3';

  // gPlayer gemius
  addScriptToDOM({ src: 'https://spir.hit.gemius.pl/gplayer.js' }).catch(() => {});

  let srcScriptVPlayer;
  if (gjvpVideos[0].templateLocation === 'audio') {
    srcScriptVPlayer = `https://host.vpplayer.tech/audio-player/${gjvpVideos[0].playerId}/${gjvpVideos[0].videoId}.js?v=1`;
  } else {
    srcScriptVPlayer = `https://host.vpplayer.tech/player/${projectID}/vpplayer.js?v=1.1`;
  }

  // gjirafa vPlayer
  addScriptToDOM({
    src: srcScriptVPlayer,
  })
    .then(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const item = gjvpVideos.find((video) => video.elementId === entry.target.id);
              if (item && item.playerId && item.videoId) {
                let fetchUrl = `${API_URL}/${item.playerId}/${item.videoId}`;
                fetchUrl += item.playerProjectId === 'agmipnzu' ? '/smyrfcjo.json' : '.json'; // Podmínka pro MallTVOlympics.
                fetch(fetchUrl)
                  .then((response) => response.json())
                  .then((data: GjvpPlayerConfigType) => {
                    const vpPlayer = window.vpPlayer(item.elementId);

                    // vypne volani reklam pro platici uzivatele
                    if (item.disableAdsForPremiumUsers === true) {
                      data.video.advertising = false;
                    }

                    // zamykání videií na naší straně
                    if (item.isVideoLockedForUser === true) {
                      data.video.videoLocking.isEnabled = true;
                      data.video.videoLocking.value = item.videoLockedTimeNumber;
                    }

                    if (item.videoLockedTimeNumber === 0 && item.isVideoLockedForUser === true) {
                      const overlayElement = document.getElementById(`overlay-${item.elementId}`);
                      if (overlayElement) {
                        // Make overlay visible
                        overlayElement.style.visibility = 'visible';
                      }
                    }

                    vpPlayer.setup(data);
                    videoEvents({
                      videoVpPlayer: vpPlayer,
                      categoryName: item.categoryName || '',
                      templateLocation: item.templateLocation || '',
                      videoPlayerId: item.playerId,
                      gemiusIdentifier: item.gemiusIdentifier || '',
                      typology: item.typology || '',
                    });
                  })
                  .catch((err) => {
                    Sentry.captureException(err);
                  });

                // Unobserve the video after initialization
                observer.unobserve(entry.target);
              }
            }
          });
        },
        { threshold: 0.5 } // Trigger when 50% of the video is visible
      );

      // Observe each video element
      gjvpVideos.forEach((item: GjvpVideosType) => {
        const videoElement = document.getElementById(item.elementId);
        if (videoElement) {
          observer.observe(videoElement);
        }
      });
    })
    .catch((err) => {
      Sentry.captureException(err);
    });
}
